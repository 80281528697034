import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getInvestors, deleteInvestor } from "../redux/actions/investors";
import {
  Box,
  Paper,
  Button,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

class InvestorAll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      investors: [],
    };
  }

  componentDidMount() {
    this.props.getInvestors({}).then((response) => {
      this.setState({ investors: response });
    });
  }

  handleDelete(id) {
    const confirmDelete = window.confirm("Are you sure you want to delete this investor?");
    if (confirmDelete) {
      this.props.deleteInvestor({ id }).then(() => {
        this.setState((prevState) => ({
          investors: prevState.investors.filter((investor) => investor.id !== id),
        }));
      });
    }
  }

  render() {
    return (
      <Container className="main-panel investor">
        <Box mt={3}>
          <Paper className="panel-container-box">
            <Typography variant="h5" mt={1} mb={2}>
              All Investors
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.props.history.push("/dashboard/investor/add")}
              sx={{ marginBottom: "20px" }}
            >
              Add Investor
            </Button>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Active</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.investors.map((investor) => (
                    <TableRow key={investor.id}>
                      <TableCell>{investor.name}</TableCell>
                      <TableCell>{investor.email}</TableCell>
                      <TableCell>{investor.is_active ? "Yes" : "No"}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={() => this.props.history.push(`/dashboard/investor/edit/${investor.id}`)}
                          sx={{ marginRight: "10px" }}
                        >
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Box>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  getInvestors,
  deleteInvestor,
};

export default withRouter(connect(null, mapDispatchToProps)(InvestorAll));