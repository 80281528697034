import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fabric } from "fabric";

import { getBrandAll } from "../redux/actions/brands";
import { addFilter } from "../redux/actions/filter";

import {
  Button,
  Grid,
  Paper,
  Box,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  TextField,
  FormControlLabel,
  Checkbox,
  Slider,
  FormLabel,
  Container,
} from "@mui/material";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import { styled } from "@mui/material/styles";

class FilterAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brands: [],
      idbrand: "",
      name: "",
      icon: null,
      base64_icon: null,
      canvas: null,
      base64_image: null,
      filterColor: [
        {
          id: 1,
          name: "grayscale",
          prop: "mode",
          mode: [
            {
              id: 1,
              name: "average",
            },
            {
              id: 2,
              name: "lightness",
            },
            {
              id: 3,
              name: "luminosity",
            },
          ],
          selectedMode: "average",
          color: null,
          filter: new fabric.Image.filters.Grayscale(),
          checked: false,
          value: null,
          min: null,
          max: null,
          step: null,
        },
        {
          id: 2,
          name: "invert",
          prop: "invert",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Invert(),
          checked: false,
          value: null,
          min: null,
          max: null,
          step: null,
        },
        {
          id: 3,
          name: "sepia",
          prop: "sepia",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Sepia(),
          checked: false,
          value: null,
          min: null,
          max: null,
          step: null,
        },
        {
          id: 4,
          name: "blackwhite",
          prop: "blackwhite",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.BlackWhite(),
          checked: false,
          value: null,
          min: null,
          max: null,
          step: null,
        },
        {
          id: 5,
          name: "brownie",
          prop: "brownie",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Brownie(),
          checked: false,
          value: null,
          min: null,
          max: null,
          step: null,
        },
        {
          id: 6,
          name: "vintage",
          prop: "vintage",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Vintage(),
          checked: false,
          value: null,
          min: null,
          max: null,
          step: null,
        },
        {
          id: 7,
          name: "kodachrome",
          prop: "kodachrome",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Kodachrome(),
          checked: false,
          value: null,
          min: null,
          max: null,
          step: null,
        },
        {
          id: 8,
          name: "technicolor",
          prop: "technicolor",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Technicolor(),
          checked: false,
          value: null,
          min: null,
          max: null,
          step: null,
        },
        {
          id: 9,
          name: "polaroid",
          prop: "polaroid",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Polaroid(),
          checked: false,
          value: null,
          min: null,
          max: null,
          step: null,
        },
        {
          id: 10,
          name: "sharpen",
          prop: "sharpen",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Convolute({
            matrix: [0, -1, 0, -1, 5, -1, 0, -1, 0],
          }),
          checked: false,
          value: null,
          min: null,
          max: null,
          step: null,
        },
        {
          id: 11,
          name: "emboss",
          prop: "emboss",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Convolute({
            matrix: [1, 1, 1, 1, 0.7, -1, -1, -1, -1],
          }),
          checked: false,
          value: null,
          min: null,
          max: null,
          step: null,
        },
        {
          id: 12,
          name: "brightness",
          prop: "brightness",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Brightness(),
          checked: false,
          value: 0.1,
          min: -1,
          max: 1,
          step: 0.003921,
        },
        {
          id: 13,
          name: "contrast",
          prop: "contrast",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Contrast(),
          checked: false,
          value: 0,
          min: -1,
          max: 1,
          step: 0.003921,
        },
        {
          id: 14,
          name: "saturation",
          prop: "saturation",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Saturation(),
          checked: false,
          value: 0,
          min: -1,
          max: 1,
          step: 0.003921,
        },
        {
          id: 15,
          name: "vibrance",
          prop: "vibrance",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Vibrance(),
          checked: false,
          value: 0,
          min: -1,
          max: 1,
          step: 0.003921,
        },
        {
          id: 16,
          name: "hue",
          prop: "rotation",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.HueRotation(),
          checked: false,
          value: 0,
          min: -1,
          max: 1,
          step: 0.003921,
        },
        {
          id: 17,
          name: "noise",
          prop: "noise",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Noise(),
          checked: false,
          value: 100,
          min: 0,
          max: 1000,
          step: 1,
        },
        {
          id: 18,
          name: "pixelate",
          prop: "blocksize",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Pixelate(),
          checked: false,
          value: 4,
          min: 2,
          max: 20,
          step: 1,
        },
        {
          id: 19,
          name: "blur",
          prop: "blur",
          mode: [],
          selectedMode: "",
          color: null,
          filter: new fabric.Image.filters.Blur(),
          checked: false,
          value: 0.1,
          min: 0,
          max: 1,
          step: 0.01,
        },
        {
          id: 20,
          name: "remove color",
          prop: "distance",
          mode: [],
          selectedMode: "",
          color: "#00f900",
          filter: new fabric.Image.filters.RemoveColor(),
          checked: false,
          value: 0.02,
          min: 0,
          max: 1,
          step: 0.01,
        },
        {
          id: 21,
          name: "blend color",
          prop: "alpha",
          mode: [
            {
              id: 1,
              name: "add",
            },
            {
              id: 2,
              name: "diff",
            },
            {
              id: 3,
              name: "subtract",
            },
            {
              id: 4,
              name: "multiply",
            },
            {
              id: 5,
              name: "screen",
            },
            {
              id: 6,
              name: "lighten",
            },
            {
              id: 7,
              name: "darken",
            },
            {
              id: 8,
              name: "overlay",
            },
            {
              id: 9,
              name: "exclusion",
            },
            {
              id: 10,
              name: "tint",
            },
          ],
          selectedMode: "add",
          color: "#00f900",
          filter: new fabric.Image.filters.BlendColor(),
          checked: false,
          value: 1,
          min: 0,
          max: 1,
          step: 0.01,
        },
        // {
        //   id: 22,
        //   name: "Blend Image",
        //   prop: "image",
        //   mode: [
        //     {
        //       id: 1,
        //       name: "multiply",
        //     },
        //     {
        //       id: 2,
        //       name: "mask",
        //     },
        //   ],
        //   selectedMode: "multiply",
        //   color: "#00f900",
        //   filter: new fabric.Image.filters.BlendImage(),
        //   checked: false,
        //   value: 1,
        //   min: 0,
        //   max: 1,
        //   step: 0.01,
        // },
      ],
    };
  }

  componentDidMount() {
    this.props
      .getBrandAll()
      .then((response) => {
        if (response.code === 200) {
          this.setState({ brands: response.data });
        } else {
          console.log("Something Error");
        }
      })
      .catch((err) => {
        console.log(err);
      });

    this.initCanvas();
  }

  initCanvas = (event) => {
    const ctx = this;
    var canvas = new fabric.Canvas("canvas-generator");
    ctx.setState({ canvas: canvas });
  };

  handleAddFile = (event) => {
    let ctx = this;
    let file = event.target.files[0];
    let reader = new FileReader();
    reader.onloadend = function () {
      ctx.setState(
        {
          base64_image: reader.result,
        },
        function () {
          fabric.Image.fromURL(ctx.state.base64_image, function (myImg) {
            let scaleI = ctx.state.canvas.width / myImg.width;
            let imgObject = myImg.set({
              left: 0,
              top: 0,
              scaleX: scaleI,
              scaleY: scaleI,
              selectable: false,
            });
            imgObject.type = "image";
            imgObject.set({
              uniScaleTransform: true,
              lockUniScaling: true,
              transparentCorners: true,
            });
            imgObject.setControlsVisibility({
              mtr: false,
            });
            ctx.state.canvas.add(imgObject);
            ctx.state.canvas.setActiveObject(imgObject);
            ctx.state.canvas.renderAll();
          });
        }
      );
    };
    reader.readAsDataURL(file);
  };

  handleRemoveFile = (event) => {
    event.preventDefault();
    var ctx = this;
    ctx.setState(
      {
        base64_image: null,
        filterColor: [
          {
            id: 1,
            name: "grayscale",
            prop: "mode",
            mode: [
              {
                id: 1,
                name: "average",
              },
              {
                id: 2,
                name: "lightness",
              },
              {
                id: 3,
                name: "luminosity",
              },
            ],
            selectedMode: "average",
            color: null,
            filter: new fabric.Image.filters.Grayscale(),
            checked: false,
            value: null,
            min: null,
            max: null,
            step: null,
          },
          {
            id: 2,
            name: "invert",
            prop: "invert",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Invert(),
            checked: false,
            value: null,
            min: null,
            max: null,
            step: null,
          },
          {
            id: 3,
            name: "sepia",
            prop: "sepia",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Sepia(),
            checked: false,
            value: null,
            min: null,
            max: null,
            step: null,
          },
          {
            id: 4,
            name: "blackwhite",
            prop: "blackwhite",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.BlackWhite(),
            checked: false,
            value: null,
            min: null,
            max: null,
            step: null,
          },
          {
            id: 5,
            name: "brownie",
            prop: "brownie",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Brownie(),
            checked: false,
            value: null,
            min: null,
            max: null,
            step: null,
          },
          {
            id: 6,
            name: "vintage",
            prop: "vintage",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Vintage(),
            checked: false,
            value: null,
            min: null,
            max: null,
            step: null,
          },
          {
            id: 7,
            name: "kodachrome",
            prop: "kodachrome",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Kodachrome(),
            checked: false,
            value: null,
            min: null,
            max: null,
            step: null,
          },
          {
            id: 8,
            name: "technicolor",
            prop: "technicolor",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Technicolor(),
            checked: false,
            value: null,
            min: null,
            max: null,
            step: null,
          },
          {
            id: 9,
            name: "polaroid",
            prop: "polaroid",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Polaroid(),
            checked: false,
            value: null,
            min: null,
            max: null,
            step: null,
          },
          {
            id: 10,
            name: "sharpen",
            prop: "sharpen",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Convolute({
              matrix: [0, -1, 0, -1, 5, -1, 0, -1, 0],
            }),
            checked: false,
            value: null,
            min: null,
            max: null,
            step: null,
          },
          {
            id: 11,
            name: "emboss",
            prop: "emboss",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Convolute({
              matrix: [1, 1, 1, 1, 0.7, -1, -1, -1, -1],
            }),
            checked: false,
            value: null,
            min: null,
            max: null,
            step: null,
          },
          {
            id: 12,
            name: "brightness",
            prop: "brightness",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Brightness(),
            checked: false,
            value: 0.1,
            min: -1,
            max: 1,
            step: 0.003921,
          },
          {
            id: 13,
            name: "contrast",
            prop: "contrast",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Contrast(),
            checked: false,
            value: 0,
            min: -1,
            max: 1,
            step: 0.003921,
          },
          {
            id: 14,
            name: "saturation",
            prop: "saturation",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Saturation(),
            checked: false,
            value: 0,
            min: -1,
            max: 1,
            step: 0.003921,
          },
          {
            id: 15,
            name: "vibrance",
            prop: "vibrance",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Vibrance(),
            checked: false,
            value: 0,
            min: -1,
            max: 1,
            step: 0.003921,
          },
          {
            id: 16,
            name: "hue",
            prop: "rotation",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.HueRotation(),
            checked: false,
            value: 0,
            min: -1,
            max: 1,
            step: 0.003921,
          },
          {
            id: 17,
            name: "noise",
            prop: "noise",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Noise(),
            checked: false,
            value: 100,
            min: 0,
            max: 1000,
            step: 1,
          },
          {
            id: 18,
            name: "pixelate",
            prop: "blocksize",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Pixelate(),
            checked: false,
            value: 4,
            min: 2,
            max: 20,
            step: 1,
          },
          {
            id: 19,
            name: "blur",
            prop: "blur",
            mode: [],
            selectedMode: "",
            color: null,
            filter: new fabric.Image.filters.Blur(),
            checked: false,
            value: 0.1,
            min: 0,
            max: 1,
            step: 0.01,
          },
          {
            id: 20,
            name: "remove color",
            prop: "distance",
            mode: [],
            selectedMode: "",
            color: "#00f900",
            filter: new fabric.Image.filters.RemoveColor(),
            checked: false,
            value: 0.02,
            min: 0,
            max: 1,
            step: 0.01,
          },
          {
            id: 21,
            name: "blend color",
            prop: "alpha",
            mode: [
              {
                id: 1,
                name: "add",
              },
              {
                id: 2,
                name: "diff",
              },
              {
                id: 3,
                name: "subtract",
              },
              {
                id: 4,
                name: "multiply",
              },
              {
                id: 5,
                name: "screen",
              },
              {
                id: 6,
                name: "lighten",
              },
              {
                id: 7,
                name: "darken",
              },
              {
                id: 8,
                name: "overlay",
              },
              {
                id: 9,
                name: "exclusion",
              },
              {
                id: 10,
                name: "tint",
              },
            ],
            selectedMode: "add",
            color: "#00f900",
            filter: new fabric.Image.filters.BlendColor(),
            checked: false,
            value: 1,
            min: 0,
            max: 1,
            step: 0.01,
          },
        ],
      },
      function () {
        ctx.state.canvas.remove(ctx.state.canvas.getActiveObject());
      }
    );
  };

  handleSubmit = (event) => {
    const ctx = this;
    event.preventDefault();
    const answer = window.confirm("Are you sure?");
    if (answer) {
      this.props
        .addFilter({
          name: ctx.state.name,
          idbrand: ctx.state.idbrand,
          filter: ctx.state.filterColor,
          base64_icon: ctx.state.base64_icon,
          base64_image: ctx.state.base64_image,
        })
        .then((response) => {
          ctx.props.history.replace("/dashboard/filter");
        })
        .catch((err) => {
          console.log("err: ", err);
        });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  filterColor = (value, event) => {
    const ctx = this;
    const index = ctx.state.filterColor.findIndex(
      (item) => item.id === value.id
    );
    let items = [...this.state.filterColor];
    if (index !== -1) {
      let item = { ...items[index] };
      item.checked = event.target.checked;
      items[index] = item;
      ctx.setState({ filterColor: items }, function () {
        this.applyFilterColor(index, item);
        if (item.value !== null) {
          this.applyFilterColorValue(index, item);
        }
        if (item.selectedMode !== "") {
          this.applyFilterColorMode(index, item);
        }
        if (item.color !== null) {
          this.applyFilterColorPicker(index, item);
        }
      });
    }
  };

  filterColorValue = (value, event) => {
    const ctx = this;
    const index = ctx.state.filterColor.findIndex(
      (item) => item.id === value.id
    );
    let items = [...this.state.filterColor];
    if (index !== -1) {
      let item = { ...items[index] };
      item.value = event.target.value;
      items[index] = item;
      ctx.setState({ filterColor: items }, function () {
        if (item.value !== null) {
          this.applyFilterColorValue(index, item);
        }
      });
    }
  };

  filterColorMode = (value, event) => {
    const ctx = this;
    const index = ctx.state.filterColor.findIndex(
      (item) => item.id === value.id
    );
    let items = [...this.state.filterColor];
    if (index !== -1) {
      let item = { ...items[index] };
      item.selectedMode = event.target.value;
      items[index] = item;
      ctx.setState({ filterColor: items }, function () {
        if (item.selectedMode !== "") {
          this.applyFilterColorMode(index, item);
        }
      });
    }
  };

  filterColorPicker = (value, event) => {
    const ctx = this;
    const index = ctx.state.filterColor.findIndex(
      (item) => item.id === value.id
    );
    let items = [...this.state.filterColor];
    if (index !== -1) {
      let item = { ...items[index] };
      console.log("itemcolor: ", item);
      console.log("event.target.value: ", event.target.value);
      item.color = event.target.value;
      items[index] = item;
      ctx.setState({ filterColor: items }, function () {
        if (item.color !== null) {
          this.applyFilterColorPicker(index, item);
        }
      });
    }
  };

  applyFilterColor = (index, item) => {
    var ctx = this;
    var imgObject = ctx.state.canvas.getActiveObject();

    if (imgObject.filters[index]) {
      delete imgObject.filters[index];
    } else {
      imgObject.filters[index] = item.filter;
    }
    imgObject.applyFilters();
    ctx.state.canvas.renderAll();
  };

  applyFilterColorValue = (index, item) => {
    var ctx = this;
    var imgObject = ctx.state.canvas.getActiveObject();
    if (imgObject.filters[index]) {
      imgObject.filters[index][item.prop] = item.value;
      imgObject.applyFilters();
      ctx.state.canvas.renderAll();
    }
  };

  applyFilterColorMode = (index, item) => {
    var ctx = this;
    var imgObject = ctx.state.canvas.getActiveObject();
    if (imgObject.filters[index]) {
      imgObject.filters[index]["mode"] = item.selectedMode;
      imgObject.applyFilters();
      ctx.state.canvas.renderAll();
    }
  };

  applyFilterColorPicker = (index, item) => {
    var ctx = this;
    var imgObject = ctx.state.canvas.getActiveObject();
    if (imgObject.filters[index]) {
      imgObject.filters[index]["color"] = item.color;
      imgObject.applyFilters();
      ctx.state.canvas.renderAll();
    }
  };

  handleChangeFile = (event) => {
    const ctx = this;
    const file = event.target.files[0];
    var reader = new FileReader();

    reader.onloadend = (element) => {
      ctx.setState({
        icon: file,
        base64_icon: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    const Input = styled("input")({
      display: "none",
    });

    return (
      <Container className="main-panel booth">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} xs={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">
              {!this.props.title ? "" : this.props.title}
            </Typography>
          </Grid>
        </Grid>
        <Paper>
          <Grid container spacing={0}>
            <Grid item md={6}>
              <Stack spacing={2} direction={"column"} alignItems={"center"}>
                <canvas
                  width="426"
                  height="635"
                  style={{ width: "426px", height: "635px" }}
                  id="canvas-generator"
                ></canvas>
                {this.state.base64_image ? (
                  <Button
                    size="small"
                    color="error"
                    className="input-image-file"
                    name="base64_image"
                    variant="contained"
                    sx={{ marginBottom: "0px", width: 426 }}
                    onClick={this.handleRemoveFile}
                  >
                    Delete Photo
                  </Button>
                ) : (
                  <Button
                    fullWidth
                    size="small"
                    className="input-image-file"
                    component="label"
                    variant="contained"
                    onKeyDown={(e) => e.keyCode === 32}
                    sx={{ marginBottom: "0px", width: 426 }}
                  >
                    Upload Photo
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      name="base64_image"
                      onChange={this.handleAddFile}
                    />
                  </Button>
                )}
              </Stack>
            </Grid>
            <Grid item md={6}>
              <Stack direction={"column"} spacing={2}>
                <Box component="form" onSubmit={this.handleSubmit}>
                  <Box
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#fafafa",
                      padding: "10px",
                      marginTop: "10px",
                      border: "1px solid #d9d9d9",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ marginBottom: "10px" }}
                    >
                      Data Filter
                    </Typography>
                    <FormGroup>
                      <FormLabel sx={{ marginBottom: "5px" }}>
                        Icon Filter
                      </FormLabel>
                      <label htmlFor="image" style={{ width: "fit-content" }}>
                        <Input
                          accept="image/*"
                          id="image"
                          name="image"
                          onChange={this.handleChangeFile}
                          multiple
                          type="file"
                        />
                        {this.state.base64_icon ? (
                          <Box
                            sx={{
                              background: "url(" + this.state.base64_icon + ")",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              height: "84.38px",
                              width: 150,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              cursor: "pointer",
                              marginBottom: "12px",
                            }}
                          ></Box>
                        ) : (
                          <Box
                            sx={{
                              background: "#979797",
                              height: "84.38px",
                              width: 150,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              cursor: "pointer",
                              marginBottom: "12px",
                            }}
                          >
                            <ImageNotSupportedIcon />
                            <Typography variant="p">Icon not found</Typography>
                          </Box>
                        )}
                      </label>
                      {this.state.brands !== null ? (
                        <FormControl sx={{ minWidth: 120 }}>
                          <InputLabel id="select-brand-label">Brand</InputLabel>
                          <Select
                            size="small"
                            labelId="select-brand-label"
                            id="select-brand"
                            name="idbrand"
                            value={this.state.idbrand}
                            label="Brand"
                            required
                            onChange={this.handleChange}
                            sx={{ marginBottom: "10px" }}
                          >
                            {this.state.brands.map((item) => {
                              return (
                                <MenuItem value={item.id} key={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      ) : (
                        <Typography>Loading...</Typography>
                      )}
                      <TextField
                        size="small"
                        fullWidth
                        type="text"
                        placeholder="Frame Name"
                        label="Name"
                        name="name"
                        required
                        sx={{ marginBottom: "10px" }}
                        onChange={this.handleChange}
                        value={this.state.name}
                      ></TextField>
                    </FormGroup>
                  </Box>
                  <Box
                    sx={{
                      borderRadius: "5px",
                      backgroundColor: "#fafafa",
                      padding: "10px",
                      marginTop: "10px",
                      border: "1px solid #d9d9d9",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ marginBottom: "10px" }}
                    >
                      Colormatrix Filters:
                    </Typography>
                    <FormGroup
                      sx={{
                        alignItems: "flex-start",
                        flexWrap: "nowrap",
                        maxHeight: 420,
                        overflow: "auto",
                      }}
                    >
                      {this.state.filterColor.map((element, index) => {
                        return (
                          <Box key={index}>
                            <FormControlLabel
                              sx={{
                                marginLeft: 0,
                                textTransform: "capitalize",
                              }}
                              control={<Checkbox checked={element.checked} />}
                              onChange={this.filterColor.bind(this, element)}
                              label={element.name}
                              disabled={this.state.base64_image ? false : true}
                              labelPlacement="start"
                              name={element.name}
                            />
                            {element.mode.length !== 0 ? (
                              <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">
                                  Mode
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  size="small"
                                  value={element.selectedMode}
                                  name={element.name}
                                  label={"Mode"}
                                  disabled={element.checked ? false : true}
                                  onChange={this.filterColorMode.bind(
                                    this,
                                    element
                                  )}
                                >
                                  {element.mode.map((el, i) => {
                                    return (
                                      <MenuItem key={el.id} value={el.name}>
                                        {el.name}
                                      </MenuItem>
                                    );
                                  })}
                                </Select>
                              </FormControl>
                            ) : null}
                            {element.color !== null ? (
                              <>
                                <br />
                                <TextField
                                  size="small"
                                  type="color"
                                  placeholder={element.name}
                                  label={element.name}
                                  name={element.name}
                                  sx={{ width: "20%", marginTop: "12px" }}
                                  onBlur={this.filterColorPicker.bind(
                                    this,
                                    element
                                  )}
                                  disabled={element.checked ? false : true}
                                  defaultValue={element.color}
                                ></TextField>
                              </>
                            ) : null}
                            {element.value !== null ? (
                              <Slider
                                sx={{ width: "500px" }}
                                aria-label={element.name}
                                value={element.value}
                                name={element.name}
                                valueLabelDisplay="auto"
                                step={element.step}
                                min={element.min}
                                max={element.max}
                                onChange={this.filterColorValue.bind(
                                  this,
                                  element
                                )}
                                disabled={element.checked ? false : true}
                              />
                            ) : null}
                          </Box>
                        );
                      })}
                    </FormGroup>
                  </Box>
                  {this.props.permissions.indexOf("create filter") !== -1 ? (
                    <Button
                      fullWidth
                      color="success"
                      type="submit"
                      variant="contained"
                      sx={{ marginTop: "12px" }}
                    >
                      Create
                    </Button>
                  ) : null}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getBrandAll: (data) => dispatch(getBrandAll(data)),
    addFilter: (data) => dispatch(addFilter(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FilterAdd)
);
