import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import { CSVLink } from "react-csv";
import InfiniteScroll from "react-infinite-scroll-component";

import { getGifAll } from "../redux/actions/gif";
import { getBoothAll } from "../redux/actions/booths";

import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  Paper,
  Button,
  Container,
  Typography,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  Modal,
  Box,
  IconButton,
  Autocomplete,
  TextField,
  CardMedia
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

class GifAll extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies),
  };
  constructor(props) {
    super(props);
    this.state = {
      filter_idbooth: "",
      filter_namebooth: "",
      filter_name: "",
      filter_boothkey: "",
      filter_perpage: 0,
      filter_order: "",
      filter_sort: "",
      perpage: [30, 50, 100, 250, 500, 1000],
      orderBy: [
        { id: "idbooth", name: "Booth" },
        { id: "created_at", name: "Date Time" },
      ],
      sortBy: [
        { id: "ASC", name: "Ascending" },
        { id: "DESC", name: "Descending" },
      ],
      data: [],
      booth: [],
      hasMore: true,
      preview: false,
      page: 1,
      tmpFile: "",
      is_loading: false,
      header: [
        { label: "Link", key: "giffullurl" },
        { label: "Booth", key: "booth.name" },
        { label: "Date Time", key: "created_at" },
      ],
    };
  }

  componentDidMount() {
    const ctx = this;
    ctx.props
      .getGifAll({
        perpage: this.state.perpage[this.state.filter_perpage],
        page: this.state.page,
      })
      .then(async (e) => {
        if (e.code === 200) {
          if (e.data !== null) {
            ctx.setState({
              data: e.data,
              page: 1,
              hasMore:
                this.state.perpage[this.state.filter_perpage] !== e.data.length
                  ? false
                  : true,
            });
          } else {
            ctx.setState({ data: [], hasMore: false });
          }
        } else {
          ctx.setState({ err: e.message });
        }
        await this.fetchCookies();
      })
      .catch((e) => {
        alert("Something Error");
      });

    ctx.props.getBoothAll().then((res) => {
      this.setState({
        booth: res.data,
      });
    });
  }

  componentDidUpdate(prevProv, prevState) {
    if (prevProv.cookies !== this.props.cookies) {
      this.fetchCookies();
    }
  }

  downloadFile = (value, elm) => {
    const link = document.createElement("a");
    link.href = value;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  fetchCookies = () => {
    var gif_cookies = this.props.cookies.get("gif_cookies");
    if (gif_cookies !== undefined) {
      this.setState(
        {
          filter_idbooth: gif_cookies.filter_idbooth,
          filter_namebooth: gif_cookies.filter_namebooth,
          filter_order: gif_cookies.filter_order,
          filter_sort: gif_cookies.filter_sort,
        },
        () => {
          this.buttonElement.click();
        }
      );
    }
  };

  loadFunc = () => {
    let params = this.params();
    params.page = this.state.page + 1;
    const ctx = this;
    const nextpage = this.state.page + 1;
    this.props
      .getGifAll(params)
      .then((e) => {
        if (e.code === 200) {
          ctx.setState({
            page: nextpage,
          });
          if (e.data !== null) {
            setTimeout(() => {
              ctx.setState(
                {
                  data: this.state.data.concat(e.data),
                },
                function () {
                  if (
                    e.data.length !==
                    this.state.perpage[this.state.filter_perpage]
                  ) {
                    this.setState({ hasMore: false });
                  }
                }
              );
            }, 1500);
          } else {
            this.setState({ hasMore: false });
          }
        } else {
          ctx.setState({ err: e.message });
        }
      })
      .catch((e) => {
        alert("Something Error");
      });
  };

  previewGif = (value, elm) => {
    this.setState({
      preview: true,
      tmpFile: value,
    });
  };

  handleClose = () => {
    this.setState({
      preview: false,
      tmpFile: "",
    });
  };

  handleChangeFilter = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  params = () => {
    let params = {};
    if (this.state.filter_idbooth !== "") {
      params.idbooth = this.state.filter_idbooth;
    }
    if (this.state.filter_order !== "") {
      params.order = this.state.filter_order;
    }
    if (this.state.filter_sort !== "") {
      params.sort = this.state.filter_sort;
    }
    params.perpage = this.state.perpage[this.state.filter_perpage];
    params.page = 1;

    return params;
  };

  handleFilterSubmit = (e) => {
    this.setState({ is_loading: true });
    e.preventDefault();
    const doc = document.getElementsByClassName("infinite-scroll-component");
    if (doc[0]) {
      doc[0].scrollTo({
        top: 0,
        // behavior: "smooth",
      });
    }

    let params = this.params();

    const { cookies } = this.props;
    let expires = new Date();
    let data = {
      filter_idbooth: this.state.filter_idbooth,
      filter_namebooth: this.state.filter_namebooth,
      filter_order: this.state.filter_order,
      filter_sort: this.state.filter_sort,
    };
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);
    cookies.set("gif_cookies", data, { path: "/", expires: expires });

    const ctx = this;
    ctx.props.getGifAll(params).then(async (e) => {
      if (e.code === 200) {
        if (e.data !== null) {
          this.setState({
            data: e.data,
            is_loading: false,
            hasMore: e.data.length !== params.perpage ? false : true,
            page: 1,
          });
        } else {
          this.setState({
            data: [],
            is_loading: false,
            hasMore: false,
            page: 1,
          });
        }
      } else {
        this.setState({ data: [], is_loading: false });
      }
    });
  };

  handleFilterClear = (e) => {
    this.setState(
      {
        filter_idbooth: "",
        filter_namebooth: "",
        filter_order: "",
        filter_sort: "",
        filter_perpage: 0,
      },
      () => {
        this.props.cookies.remove("gif_cookies", { path: "/", expires: 0 });
        this.props
          .getGifAll({
            perpage: this.state.perpage[this.state.filter_perpage],
            page: this.state.page,
          })
          .then((e) => {
            if (e.code === 200) {
              if (e.data !== null) {
                this.setState({ data: e.data });
              } else {
                this.setState({ data: [], hasMore: false });
              }
            } else {
              this.setState({ err: e.message });
            }
          })
          .catch((e) => {
            alert("Something Error");
          });
      }
    );
  };

  render() {
    var tableBody;
    if (this.state.data !== null) {
      tableBody = this.state.data.map((item, idx) => {
        return (
          <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
            <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
              {(idx += 1)}
            </TableCell>
            <TableCell align="left">
              <Typography
                variant="p"
                color="primary"
                sx={{ cursor: "pointer" }}
                onClick={this.previewGif.bind(this, item.giffullurl)}
              >
                {item.giffullurl}
              </Typography>
              {/* <img
                src={item.giffullurl}
                width={60}
                height={80}
                alt={item.giffullurl}
              /> */}
              {/* {item.giffullurl} */}
            </TableCell>
            <TableCell align="left">{item.booth.name}</TableCell>
            <TableCell align="left">{item.created_at}</TableCell>
          </TableRow>
        );
      });
    }

    return (
      <Container className="main-panel booth">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} xs={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">
              {!this.props.title ? "" : this.props.title}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          className="header-panel"
          alignItems="center"
          sx={{
            paddingTop: "10px",
            paddingBottom: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid
            item
            md={12}
            sx={{
              display: "flex",
              gap: "10px",
              justifyContent: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {/* <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id="select-booth-label">Booth</InputLabel>
              <Select
                size="small"
                label="Booth"
                name="filter_idbooth"
                id="select-booth"
                labelId="select-booth-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_idbooth}
              >
                <MenuItem value={0} key={-1}>
                  All
                </MenuItem>
                {this.state.booth.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}
            <Autocomplete
              freeSolo
              name="filter_idbooth"
              id="free-solo-2-demo"
              size="small"
              disableClearable
              onChange={(e, v) => {
                this.setState({
                  filter_idbooth: v.id,
                  filter_namebooth: v.label,
                });
              }}
              value={this.state.filter_namebooth || ""}
              options={this.state.booth.map((option) => ({
                id: option.id,
                label: option.name,
              }))}
              sx={{ minWidth: 200 }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Booth"
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                    }}
                  />
                );
              }}
            />
            <FormControl>
              <InputLabel id="select-perpage-label">Per Page</InputLabel>
              <Select
                size="small"
                label="Perpage"
                name="filter_perpage"
                id="select-perpage"
                labelId="select-perpage-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_perpage}
                sx={{ width: "100px" }}
              >
                {/* <MenuItem value={0} key={-1}>25</MenuItem> */}
                {this.state.perpage.map((item, idx) => {
                  return (
                    <MenuItem value={idx} key={idx}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Order By</InputLabel>
              <Select
                size="small"
                label="Order By"
                name="filter_order"
                id="select-order"
                labelId="select-order-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_order}
                sx={{ width: "150px" }}
              >
                {this.state.orderBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Sort By</InputLabel>
              <Select
                size="small"
                label="Sort By"
                name="filter_sort"
                id="select-sort"
                labelId="select-sort-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_sort}
                sx={{ width: "150px" }}
              >
                {this.state.sortBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {!this.state.is_loading ? (
              <Button
                ref={(button) => (this.buttonElement = button)}
                variant="contained"
                onClick={this.handleFilterSubmit}
              >
                Filter
              </Button>
            ) : (
              <Typography className="btn-loading-filter">
                HARAP SABAR...
              </Typography>
            )}
            <Button variant="contained" onClick={this.handleFilterClear}>
              Clear Filter
            </Button>
            {this.state.data !== null ? (
              <CSVLink
                className="btn-export-report"
                style={{ height: "40px" }}
                filename={"gif-data.csv"}
                data={this.state.data}
                headers={this.state.header}
              >
                DOWNLOAD
              </CSVLink>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
        <Paper>
          <TableContainer sx={{ maxHeight: 570 }}>
            <InfiniteScroll
              // id="infinite-scroll-component"
              height={500}
              dataLength={this.state.data.length}
              next={this.loadFunc}
              hasMore={this.state.hasMore}
              loader={
                <Typography
                  id="load-data"
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px",
                  }}
                >
                  Loading...
                </Typography>
              }
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell align="left">Link</TableCell>
                    <TableCell align="left">Booth</TableCell>
                    <TableCell align="left">Date Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{tableBody}</TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Paper>
        <Modal
          open={this.state.preview}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              width: "350px",
              height: "622.22px",
            }}
            onClick={this.downloadFile.bind(this, this.state.tmpFile)}
          >
             <CardMedia
                component="video"
                src={this.state.tmpFile}
                controls
                sx={{
                    width: '100%',
                    height: '100%',
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                }}
            />
          </Box>
        </Modal>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getGifAll: (data) => dispatch(getGifAll(data)),
    getBoothAll: (data) => dispatch(getBoothAll(data)),
    // getBoothSearch: (data) => dispatch(getBoothSearch(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(GifAll))
);
