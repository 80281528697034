import React, { Component } from "react";
import { connect } from "react-redux";
import {
  // Link,
  withRouter,
} from "react-router-dom";
import { withCookies, Cookies } from "react-cookie";
import { instanceOf } from "prop-types";
import InfiniteScroll from "react-infinite-scroll-component";

import { getVideo, deleteVideo } from "../redux/actions/video";
import { getBrandAll } from "../redux/actions/brands";

import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  Grid,
  Paper,
  Button,
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Box,
  TextField,
} from "@mui/material";

class videoAll extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies),
  };
  constructor(props) {
    super(props);
    this.state = {
      filter_perpage: 0,
      filter_order: "",
      filter_sort: "",
      filter_name: "",
      filter_idbrand: 0,
      perpage: [30, 50, 100, 250, 500, 1000],
      data: [],
      brand: [],
      orderBy: [
        { id: "name", name: "Name" },
        { id: "slug", name: "Slug" },
      ],
      sortBy: [
        { id: "ASC", name: "Ascending" },
        { id: "DESC", name: "Descending" },
      ],
      err: null,
      is_loading: false,
      page: 1,
      hasMore: false,
      preview: false,
      tmpFile: null,
    };
  }

  componentDidMount() {
    this.loadData();
    this.fetchCookies();
  }

  params = () => {
    let params = {};

    if (this.state.filter_idbrand !== 0) {
      params.idbrand = this.state.filter_idbrand;
    }
    if (this.state.filter_name !== "") {
      params.name = this.state.filter_name;
    }
    if (this.state.filter_order !== "") {
      params.order = this.state.filter_order;
    }
    if (this.state.filter_sort !== "") {
      params.sort = this.state.filter_sort;
    }
    params.perpage = this.state.perpage[this.state.filter_perpage];
    params.page = 1;
    // params.is_active = 1;

    return params;
  };

  loadData = async () => {
    const brand = await this.props.getBrandAll();
    if (brand.code === 200) {
      this.setState({ brand: brand.data });
    }

    let params = this.params();
    const data = await this.props.getVideo(params);
    if (data.code === 200) {
      console.log("data: ", data.data);
      this.setState({
        data: data.data,
        hasMore:
          this.state.perpage[this.state.filter_perpage] !== data.data.length
            ? false
            : true,
        is_loading: false,
      });
    } else {
      this.setState({
        data: [],
        hasMore: false,
        is_loading: false,
      });
    }
  };

  handleChangeFilter = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleFilterSubmit = (e) => {
    this.setState({ is_loading: true });
    e.preventDefault();

    const { cookies } = this.props;
    let expires = new Date();
    let data = {
      filter_idbrand: this.state.filter_idbrand,
      filter_perpage: this.state.filter_perpage,
      filter_order: this.state.filter_order,
      filter_sort: this.state.filter_sort,
      filter_name: this.state.filter_name,
    };
    expires.setTime(expires.getTime() + 1 * 24 * 60 * 60 * 1000);

    cookies.set("video_cookies", data, { path: "/", expires: expires });

    this.loadData();
  };

  handleFilterClear = (e) => {
    this.setState(
      {
        filter_idbrand: 0,
        filter_perpage: 0,
        filter_order: "",
        filter_sort: "",
        filter_name: "",
      },
      async function () {
        await this.props.cookies.remove("video_cookies", {
          path: "/",
          expires: 0,
        });
        await this.loadData();
      }
    );
  };

  fetchCookies = () => {
    var video_cookies = this.props.cookies.get("video_cookies");
    if (video_cookies !== undefined) {
      this.setState(
        {
          filter_idbrand: video_cookies.filter_idbrand,
          filter_name: video_cookies.filter_name,
          filter_perpage: video_cookies.filter_perpage,
          filter_order: video_cookies.filter_order,
          filter_sort: video_cookies.filter_sort,
        },
        () => {
          this.buttonElement.click();
        }
      );
    }
  };

  componentDidUpdate(prevProv, prevState) {
    if (prevProv.cookies !== this.props.cookies) {
      this.fetchCookies();
    }
  }

  previewVideo = (value, elm) => {
    this.setState({
      preview: true,
      tmpFile: value,
    });
  };

  handleClose = () => {
    this.setState({
      preview: false,
      tmpFile: null,
    });
  };

  handleDelete = (e) => {
    const ctx = this;
    e.preventDefault();
    var confirmed = window.confirm("Are you sure to delete this category?");
    if (confirmed) {
      ctx.props
        .deleteVideo({ slug: e.target.dataset.slug })
        .then((e) => {
          if (e.code === 200) {
            alert("Delete Success");
            window.location.reload();
          } else {
            alert("Something Error : " + e.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert("Something Error : " + e.message);
        });
    }
  };

  loadFunc = async () => {
    const ctx = this;
    let params = this.params();
    params.page = this.state.page + 1;
    const data = await this.props.getVideo(params);
    if (data.code === 200) {
      if (data.data !== null) {
        setTimeout(() => {
          ctx.setState({
            data: this.state.data.concat(data.data),
            hasMore:
              data.data.length !== this.state.perpage[this.state.filter_perpage]
                ? false
                : true,
            page: this.state.page + 1,
          });
        }, 1500);
      }else{
        ctx.setState({ hasMore: false, page: this.state.page + 1 });
      }
    } else {
      ctx.setState({ hasMore: false, page: this.state.page + 1 });
    }
  };

  render() {
    const url = this.props.match.url;

    return (
      <Container className="main-panel booth">
        <Grid container className="header-panel" alignItems="center" mb={1}>
          <Grid item md={6} xs={6} sx={{ justifyContent: "flex-start" }}>
            <Typography variant="h6">
              {!this.props.title ? "" : this.props.title}
            </Typography>
          </Grid>
          {this.props.permissions.indexOf("create ads") !== -1 ? (
            <Grid item md={6} xs={6} sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => {
                  this.props.history.push(url + "/add");
                }}
              >
                Add Video
              </Button>
            </Grid>
          ) : null}
        </Grid>
        <Grid
          container
          className="header-panel"
          alignItems="center"
          sx={{
            paddingTop: "10px",
            paddingBottom: "10px",
            marginBottom: "10px",
          }}
        >
          <Grid
            item
            md={12}
            xs={12}
            sx={{
              display: "flex",
              gap: "5px",
              justifyContent: "flex-start",
              flexWrap: { xs: "wrap", md: "nowrap" },
            }}
          >
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="select-brand-label">Brand</InputLabel>
              <Select
                size="small"
                label="Brand"
                name="filter_idbrand"
                id="select-brand"
                labelId="select-brand-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_idbrand}
              >
                <MenuItem value={0} key={-1}>
                  All
                </MenuItem>
                {this.state.brand.map((item) => {
                  return (
                    <MenuItem value={item.id} key={item.id}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <TextField
              size="small"
              id="input-filtername"
              name="filter_name"
              label="Filter Name"
              placeholder="Filter Name"
              onChange={this.handleChangeFilter}
              value={this.state.filter_name}
            ></TextField>

            <FormControl>
              <InputLabel id="select-perpage-label">Per Page</InputLabel>
              <Select
                size="small"
                label="Perpage"
                name="filter_perpage"
                id="select-perpage"
                labelId="select-perpage-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_perpage}
                sx={{ width: "100px" }}
              >
                {this.state.perpage.map((item, idx) => {
                  return (
                    <MenuItem value={idx} key={idx}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Order By</InputLabel>
              <Select
                size="small"
                label="Order By"
                name="filter_order"
                id="select-order"
                labelId="select-order-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_order}
                sx={{ width: "100px" }}
              >
                {this.state.orderBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl size="small">
              <InputLabel id="select-perpage-label">Sort By</InputLabel>
              <Select
                size="small"
                label="Sort By"
                name="filter_sort"
                id="select-sort"
                labelId="select-sort-label"
                onChange={this.handleChangeFilter}
                value={this.state.filter_sort}
                sx={{ width: "100px" }}
              >
                {this.state.sortBy.map((item, idx) => {
                  return (
                    <MenuItem value={item.id} key={idx}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {!this.state.is_loading ? (
              <Button
                ref={(button) => (this.buttonElement = button)}
                variant="contained"
                onClick={this.handleFilterSubmit}
              >
                Filter
              </Button>
            ) : (
              <Typography className="btn-loading-filter">
                HARAP SABAR...
              </Typography>
            )}
            <Button variant="contained" onClick={this.handleFilterClear}>
              Clear Filter
            </Button>
          </Grid>
        </Grid>
        <Paper>
          <TableContainer sx={{ maxHeight: 570 }}>
            <InfiniteScroll
              // id="infinite-scroll-component"
              height={500}
              dataLength={this.state.data.length}
              next={this.loadFunc}
              hasMore={this.state.hasMore}
              loader={
                <Typography
                  id="load-data"
                  sx={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    padding: "15px",
                  }}
                >
                  Loading...
                </Typography>
              }
            >
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell align="left">Brand</TableCell>
                    <TableCell align="left">Name</TableCell>
                    <TableCell align="left">Slug</TableCell>
                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">Video</TableCell>
                    {/* <TableCell align="left">Duration</TableCell> */}
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.data.map((item, idx) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                        <TableCell sx={{ width: "1%", whiteSpace: "nowrap" }}>
                          {(idx += 1)}
                        </TableCell>
                        <TableCell sx={{ width: "15%" }} align="left">
                          {item?.brand?.name || ""}
                        </TableCell>
                        <TableCell sx={{ width: "15%" }} align="left">
                          {item.name}
                        </TableCell>
                        <TableCell sx={{ width: "15%" }} align="left">
                          {item.slug}
                        </TableCell>
                        <TableCell sx={{ width: "15%" }} align="left">
                          {item.description}
                        </TableCell>
                        <TableCell sx={{ width: "20%" }} align="left">
                          <Typography
                            variant="p"
                            color="primary"
                            sx={{ cursor: "pointer" }}
                            onClick={this.previewVideo.bind(
                              this,
                              item.fullurl_video
                            )}
                          >
                            {item.fullurl_video}
                          </Typography>
                        </TableCell>
                        {/* <TableCell align="left">
                          {item.duration / 1000} Detik
                        </TableCell> */}
                        <TableCell
                          align="left"
                          sx={{ width: "10%", whiteSpace: "nowrap" }}
                        >
                          {item.is_active === true ? "Active" : "Not Active"}
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ width: "10p%", whiteSpace: "nowrap" }}
                        >
                          <Stack direction={"row"} spacing={2}>
                            <Button
                              variant="contained"
                              color="warning"
                              disabled={
                                this.props.permissions.indexOf("edit ads") !==
                                -1
                                  ? false
                                  : true
                              }
                              onClick={(e) => {
                                this.props.history.push(
                                  url + "/edit/" + item.slug
                                );
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              data-slug={item.slug}
                              disabled={
                                this.props.permissions.indexOf("delete ads") !==
                                -1
                                  ? false
                                  : true
                              }
                              onClick={this.handleDelete}
                            >
                              Delete
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </InfiniteScroll>
          </TableContainer>
        </Paper>
        <Modal
          open={this.state.preview}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {this.state.tmpFile ? (
              <video
                src={this.state.tmpFile}
                width={720}
                height={405}
                alt="video_preview"
                controls
              />
            ) : (
              <Box
                sx={{
                  height: 405,
                  width: 720,
                  background: "#979797",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              ></Box>
            )}
          </Box>
        </Modal>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getBrandAll: (data) => dispatch(getBrandAll(data)),
    getVideo: (data) => dispatch(getVideo(data)),
    deleteVideo: (data) => dispatch(deleteVideo(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withCookies(videoAll))
);
