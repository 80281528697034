import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const getVideo = (payload) => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "ads/all", {
        params: payload,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const addVideo = (payload) => {
  return (dispatch) => {
    return axios
      .post(BASE_URL + BASE_VERSION + "ads", payload, {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          payload.progress(Math.floor((loaded / total) * 100));
        },
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      });
  };
};

export const updateVideo = (payload) => {
  return (dispatch) => {
    return axios
      .put(BASE_URL + BASE_VERSION + "ads/" + payload.slug, payload, {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          payload.progress(Math.floor((loaded / total) * 100));
        },
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      });
  };
};

export const syncVideo = (payload) => {
  return (dispatch) => {
    return axios
      .post(BASE_URL + BASE_VERSION + "ads/sync", payload, {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          payload.progress(Math.floor((loaded / total) * 100));
        },
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
      })
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        payload = error;
        return payload;
      });
  };
};

export const getDetailVideo = (payload) => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "ads/" + payload.slug, {
        params: payload,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const deleteVideo = (payload) => {
  return (dispatch) => {
    return axios
      .delete(BASE_URL + BASE_VERSION + "ads/" + payload.slug, {
        params: payload,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};