import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    withRouter
} from "react-router-dom";

// import { Box, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            err:null
        };
    }

    render() {
        return (
            <Box className='main-footer'>
                <Typography variant="body2" className='footer-label'>2022 Photograms</Typography>
            </Box>            
        )
    }
}

function mapDispatchToProps(dispatch) {
    return {
        //logout: userData => dispatch(logout(userData))
    };
}

const mapStateToProps = state => ({
    ...state
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));