import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { format } from "date-fns";

import { addCoupon } from "../redux/actions/coupons";
// import { getUserAll } from "../redux/actions/users";
import { getBoothAll } from "../redux/actions/booths";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import {
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  capitalize,
} from "@mui/material";

class CouponAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        code: "",
        coupon_type: "free", //['free', 'admin_fee', 'percentage', 'amount']
        qty: 1,
        loop: 1,
        description: "",
        valid_start: "2022-08-01 00:00:00",
        valid_end: "2022-08-30 00:00:00",
        is_active: true,
        iduser: 1,
        coupon_category: "event",
        discount_percentage: 0,
        discount_amount: 0,
        applied_booth_id: ""
      },
      categories: ["event", "retake", "operator", "ops", "booth", "activation"],
      users: null,
      booths: [],
      err: null,
      uploading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    let tmpVal = e.target.value;

    if (e.target.name === "is_active") {
      tmpVal = JSON.parse(e.target.checked);
    }

    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: tmpVal,
      },
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    // return true;
    const answer = window.confirm("Are you sure?");
    if (answer) {
      var ctx = this;
      ctx.setState({ uploading: true });
      var couponObj = {
        coupon_type: ctx.state.data.coupon_type, //['free', 'admin_fee', 'percentage', 'amount']
        qty: parseInt(ctx.state.data.qty),
        description: ctx.state.data.description,
        valid_start: ctx.state.data.valid_start,
        valid_end: ctx.state.data.valid_end,
        is_active: ctx.state.data.is_active,
        loop: ctx.state.data.loop,
        iduser: ctx.props.userData.id,
        coupon_issuer: ctx.props.userData.id,
        coupon_category: ctx.state.data.coupon_category,
        applied_booth_id: ctx.state.data.applied_booth_id && ctx.state.applied_booth_id !== 'all' ? ctx.state.data.applied_booth_id : null,
        discount_percentage:
          ctx.state.data.coupon_type === "percentage"
            ? ctx.state.data.discount_percentage
            : 0,
        discount_amount:
          ctx.state.data.coupon_type === "amount"
            ? ctx.state.data.discount_amount
            : 0,
      };
      if (ctx.state.data.code !== "") {
        couponObj.code = ctx.state.data.code;
      }

      console.log(couponObj);

      this.props
        .addCoupon(couponObj)
        .then((e) => {
          ctx.setState({ uploading: false });
          if (e.code === 200) {
            ctx.props.history.replace("/dashboard/coupon");
          } else {
            alert(e.message);
          }
        })
        .catch((err) => {
          console.log(err);
          alert(err.message);
        });
    } else {
      console.log("dismiss!");
    }
  }

  componentDidMount() {
    // this.props.getUserAll().then((e) => {
    //   this.setState({ users: e.data });
    // });
    this.props.getBoothAll().then((e) => {
      this.setState({ booths: e.data });
    });

    let futureExpiredDate = new Date();
    futureExpiredDate.setDate(futureExpiredDate.getDate() + 30);
    futureExpiredDate.setHours(23);
    futureExpiredDate.setMinutes(59);
    futureExpiredDate.setSeconds(0);

    this.setState({
      data: {
        ...this.state.data,
        valid_start: format(new Date(), "yyyy-MM-dd HH:mm:ss"),
        valid_end: format(futureExpiredDate, "yyyy-MM-dd HH:mm:ss"),
      },
    });
  }

  render() {
    return (
      <Container className="main-panel coupon">
        <Box mt={3}>
          <Paper
            component="form"
            className="panel-container-box"
            onSubmit={this.handleSubmit}
          >
            <Typography variant="h5" mt={1} mb={2}>
              Create Voucher
            </Typography>
            <FormGroup>
              <TextField
                size="small"
                id="input-couponcode"
                name="code"
                label="Code"
                helperText="Huruf kecil semua, 5-10 character"
                value={this.state.data.code}
                onChange={this.handleChange}
                sx={{ marginBottom: "20px" }}
              />

              {this.state.booths.length ? (
                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="select-booth-label">Booth</InputLabel>
                  <Select
                    size="small"
                    labelId="select-booth-label"
                    id="select-booth"
                    name="applied_booth_id"
                    value={this.state.data.applied_booth_id}
                    label="ID Brand"
                    onChange={this.handleChange}
                  >
                  <MenuItem value="all" key="all">
                    {capitalize("All Booth")}
                  </MenuItem>
                    {this.state.booths.map((item) => {
                      return (
                        <MenuItem value={item.id} key={item.id}>
                          {capitalize(item.name)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText sx={{ marginBottom: "20px" }}>
                    Voucher diperuntukan untuk booth
                  </FormHelperText>
                </FormControl>
              ) : (
                <Typography>Loading...</Typography>
              )}

              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="select-user-label">Type</InputLabel>
                <Select
                  size="small"
                  labelId="select-type-label"
                  id="select-type"
                  name="coupon_type"
                  value={this.state.data.coupon_type}
                  label="ID User"
                  onChange={this.handleChange}
                >
                  <MenuItem value={"free"}>FREE</MenuItem>
                  <MenuItem value={"percentage"}>PERCENTAGE</MenuItem>
                  <MenuItem value={"amount"}>AMOUNT</MenuItem>
                </Select>
                <FormHelperText sx={{ marginBottom: "20px" }}>
                  Voucher Type
                </FormHelperText>
              </FormControl>

              {this.state.data.coupon_type === "percentage" ? (
                <TextField
                  size="small"
                  id="input-percentage"
                  type="number"
                  name="discount_percentage"
                  label="Voucher Percentage"
                  helperText="Minimal 0 - 100"
                  value={this.state.data.discount_percentage}
                  onChange={this.handleChange}
                  sx={{ marginBottom: "20px" }}
                />
              ) : null}

              {this.state.data.coupon_type === "amount" ? (
                <TextField
                  size="small"
                  id="input-amount"
                  type="number"
                  name="discount_amount"
                  label="Voucher Amont"
                  helperText="Nominal Diskon"
                  value={this.state.data.discount_amount}
                  onChange={this.handleChange}
                  sx={{ marginBottom: "20px" }}
                />
              ) : null}

              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="select-user-label">Voucher Category</InputLabel>
                <Select
                  size="small"
                  labelId="select-coupon_category-label"
                  id="select-coupon_category"
                  name="coupon_category"
                  value={this.state.data.coupon_category}
                  label="Coupon Category"
                  onChange={this.handleChange}
                >
                  {this.state.categories.map((item, idx) => {
                    return (
                      <MenuItem value={item} key={idx}>
                        {capitalize(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText sx={{ marginBottom: "20px" }}>
                  Voucher Category
                </FormHelperText>
              </FormControl>

              <TextField
                size="small"
                id="input-coupondescription"
                name="description"
                label="Coupon Description"
                helperText="Deskripsi Coupon"
                value={
                  this.state.data.description !== null
                    ? this.state.data.description
                    : ""
                }
                onChange={this.handleChange}
                multiline
                rows={3}
                sx={{ marginBottom: "20px" }}
              />

              <TextField
                size="small"
                id="input-couponqty"
                name="qty"
                label="Quantity"
                helperText="1 - 999"
                type="number"
                value={this.state.data.qty} //!==""?this.state.data.qty:1}
                onChange={this.handleChange}
                required
                sx={{ marginBottom: "20px" }}
              />

              <TextField
                size="small"
                id="input-couponvalid_start"
                name="valid_start"
                label="Valid Start"
                helperText="Tahun-Bulan-Tanggal jam:menit:detik"
                value={
                  this.state.data.valid_start !== null
                    ? this.state.data.valid_start
                    : ""
                }
                onChange={this.handleChange}
                sx={{ marginBottom: "20px" }}
              />

              <TextField
                size="small"
                id="input-couponvalid_end"
                name="valid_end"
                label="Valid End"
                helperText="Tahun-Bulan-Tanggal jam:menit:detik"
                value={
                  this.state.data.valid_end !== null
                    ? this.state.data.valid_end
                    : ""
                }
                onChange={this.handleChange}
                sx={{ marginBottom: "20px" }}
              />

              <TextField
                size="small"
                id="input-couponloop"
                name="loop"
                label="Jumlah unique coupon"
                helperText="Jumlah coupon yang dibuat"
                value={this.state.data.loop}
                onChange={this.handleChange}
                sx={{ marginBottom: "20px" }}
              />

              <FormControlLabel
                control={<Switch defaultChecked />}
                label="Active"
                name="is_active"
                value={this.state.data?.is_active}
                onChange={this.handleChange}
                sx={{ marginBottom: "20px", marginLeft: "5px" }}
              />

              {this.props.permissions.indexOf("create coupon") !== -1 ? (
                <>
                  {this.state.uploading ? (
                    <Box sx={{ width: "100%", textAlign: "center" }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Button type="submit" variant="contained">
                      Create
                    </Button>
                  )}
                </>
              ) : null}
            </FormGroup>
          </Paper>
        </Box>
      </Container>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    addCoupon: (data) => dispatch(addCoupon(data)),
    // getUserAll: (data) => dispatch(getUserAll(data)),
    getBoothAll: (data) => dispatch(getBoothAll(data)),
  };
}

const mapStateToProps = (state) => ({
  ...state,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CouponAdd)
);
