import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const getUserAll = () => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "user/all", {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const login = (payload) => {
  return (dispatch) => {
    return axios
      .post(BASE_URL + BASE_VERSION + "signin", {
        email: payload.email,
        password: payload.password,
      })
      .then(function (response) {
        payload = response.data;
        if (payload.code === 200) {
          dispatch({ type: "SET_LOGIN", payload });
        }
        return payload;
      })
      .catch(function (error) {
        console.log(error);
        payload = error;
        dispatch({ type: "SET_LOGOUT", payload });
        return payload;
      });
  };
};

export const checkLogin = () => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "me", {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        if (payload.code === 200) {
          dispatch({ type: "STAY_INSIDE", payload });
        } else {
          dispatch({ type: "SET_LOGOUT", payload });
        }
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    return axios
      .post(BASE_URL + BASE_VERSION + "signout", null, {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        if (payload.code === 200) {
          dispatch({ type: "SET_LOGOUT", payload });
        }
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};
