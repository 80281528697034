export const objectConfig = {
        lockUniScaling: true,
        borderColor: '#ff0000',
        cornerColor: '#00ff00',
        cornerSize: 6,
        transparentCorners: false
}

export const controlConfig =  {
        mb:false,
        ml:false,
        mt:false,
        mr:false,
        tl:false,
        tr:false,
        bl:false,
        mtr:false
}