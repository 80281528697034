// import React from 'react'
// import ReactDOM from 'react-dom'

import React from "react";
import ReactDOM from "react-dom/client";

import { Provider } from 'react-redux'
import store from './redux/store'
import App from './App'
import * as serviceWorker from './serviceWorker';
if (process.env.REACT_APP_BRANCH === 'production') {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    // </React.StrictMode>
);

// const rootElement = document.getElementById('root')
// ReactDOM.render(
//   <Provider store={store}>
//     <App />
//   </Provider>,
//   rootElement
// )

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.unregister();

// SELECT * FROM photograms_prod.transactions WHERE "status"='COMPLETED' and "idbooth" = 3 and "updated_at" > '2022-09-09 00:00:00';