import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { addInvestor } from "../redux/actions/investors";
import { getBoothAll } from "../redux/actions/booths";
import {
  Box,
  Paper,
  Button,
  Container,
  Typography,
  FormGroup,
  TextField,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

class InvestorAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      booths: [],
      selectedBooths: [],
      data: {
        name: "",
        email: "",
        is_active: true,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.props.getBoothAll().then((response) => {
      if (response.code === 200) {
        this.setState({ booths: response.data });
      }
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({
      data: {
        ...this.state.data,
        [name]: value,
      },
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.addInvestor({
      ...this.state.data,
      selectedBooths: this.state.selectedBooths,
    }).then(() => {
      this.props.history.push("/dashboard/investor");
    });
  }

  render() {
    return (
      <Container className="main-panel investor">
        <Box mt={3}>
          <Paper component="form" className="panel-container-box" onSubmit={this.handleSubmit}>
            <Typography variant="h5" mt={1} mb={2}>
              Create New Investor
            </Typography>
            <FormGroup>
              <TextField
                size="small"
                id="input-investor-name"
                name="name"
                label="Name"
                value={this.state.data.name}
                onChange={this.handleChange}
                required
                sx={{ marginBottom: "20px" }}
              />
              <TextField
                size="small"
                id="input-investor-email"
                name="email"
                label="Email"
                value={this.state.data.email}
                onChange={this.handleChange}
                required
                sx={{ marginBottom: "20px" }}
              />
              <Autocomplete
                multiple
                size="small"
                id="checkboxes-tags-demo"
                options={this.state.booths}
                disableCloseOnSelect
                getOptionLabel={(option) => option.name}
                onChange={(event, newValue) => {
                  this.setState({ selectedBooths: newValue });
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Booths" placeholder="Select Booths" />
                )}
              />
              <Button type="submit" variant="contained" sx={{ marginTop: "20px" }}>
                Create
              </Button>
            </FormGroup>
          </Paper>
        </Box>
      </Container>
    );
  }
}

const mapDispatchToProps = {
  addInvestor,
  getBoothAll,
};

export default withRouter(connect(null, mapDispatchToProps)(InvestorAdd));