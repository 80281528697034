import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const addBrand = (payload) => {
  return (dispatch) => {
    return axios
      .post(
        BASE_URL + BASE_VERSION + "brand",
        {
          name: payload.name,
          slug: payload.slug,
          description: payload.description,
          is_active: payload.is_active,
          base64_image: payload.base64_image,
        },
        {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
          },
        }
      )
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      });
  };
};

export const updateBrand = (payload) => {
  return (dispatch) => {
    return axios
      .put(
        BASE_URL + BASE_VERSION + "brand/" + payload.id,
        {
          name: payload.name,
          description: payload.description,
          is_active: payload.is_active,
          base64_image: payload.base64_image,
        },
        {
          headers: {
            Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
          },
        }
      )
      .then(function (response) {
        payload = response.data;
        return payload;
      })
      .catch(function (error) {
        console.log(error);
        payload = error;
        return payload;
      });
  };
};

export const getBrand = (payload) => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "brand/" + payload.id, {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const getBrandAll = (payload) => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "brand/all", {
        params: payload,
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const deleteBrand = (payload) => {
  return (dispatch) => {
    return axios
      .delete(BASE_URL + BASE_VERSION + "brand/" + payload.id, {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};

export const brandSlug = () => {
  return (dispatch) => {
    return axios
      .get(BASE_URL + BASE_VERSION + "brand/slugs", {
        headers: {
          Authorization: "Bearer " + window.localStorage.getItem("app_pt"),
        },
        validateStatus: () => true,
      })
      .then(function (response) {
        let payload = response.data;
        return payload;
      })
      .catch(function (error) {
        let payload = error;
        return payload;
      });
  };
};
