import axios from "axios";
import { BASE_URL, BASE_VERSION } from "../config";

export const addInvestor = (payload) => {
  return (dispatch) => {
    return axios
      .post(
        `${BASE_URL}${BASE_VERSION}investor`,
        {
          name: payload.name,
          email: payload.email,
          is_active: payload.is_active,
          booths: payload.selectedBooths,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("app_pt")}`,
          },
        }
      )
      .then((response) => response.data)
      .catch((error) => error);
  };
};

export const updateInvestor = (payload) => {
  return (dispatch) => {
    return axios
      .put(
        `${BASE_URL}${BASE_VERSION}investor/${payload.id}`,
        {
          name: payload.name,
          email: payload.email,
          is_active: payload.is_active,
          booths: payload.selectedBooths,
        },
        {
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("app_pt")}`,
          },
        }
      )
      .then((response) => response.data)
      .catch((error) => error);
  };
};

export const getInvestor = (payload) => {
  return (dispatch) => {
    return axios
      .get(`${BASE_URL}${BASE_VERSION}investor/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("app_pt")}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => error);
  };
};

export const getInvestors = (payload) => {
  return (dispatch) => {
    return axios
      .get(`${BASE_URL}${BASE_VERSION}investor/all`, {
        params: payload,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("app_pt")}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => error);
  };
};

export const deleteInvestor = (payload) => {
  return (dispatch) => {
    return axios
      .delete(`${BASE_URL}${BASE_VERSION}investor/${payload.id}`, {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("app_pt")}`,
        },
      })
      .then((response) => response.data)
      .catch((error) => error);
  };
};